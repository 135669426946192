






















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Moment } from "moment";

@Component({
  components: {}
})
export default class RequestDialog extends Vue {
  @Prop() editingDay!: {
    day: Moment;
    dayParts: {
      morning: boolean;
      afternoon: boolean;
    };
  };
  @Prop() visible!: string;

  setAvailability(dayPart: string, value: boolean) {
    this.$emit("setAvailability", {
      dayPart: dayPart,
      value: value
    });
  }

  capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  saveDayParts() {
    this.$emit("saveDayParts");
  }

  handleClose() {
    this.$emit("update:visible", false);
  }

  handleSaveClose() {
    this.$emit("handleSaveClose");
  }
}
