





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SavedDialog extends Vue {
  @Prop() title!: string;
  @Prop() text!: string;
  @Prop() visible!: string;
  @Prop() buttonText!: string;

  handleSaveClose() {
    this.$emit("handleSaveClose");
  }
}
