


























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import Calendar from "@/common/components/Calendar/Calendar.vue";
import SavedDialog from "@/common/components/Dialogs/SavedDialog.vue";
import RequestDialog from "@/common/components/Dialogs/RequestDialog.vue";
import { ReplacementInfoInterface } from "@/modules/director/interfaces/ReplacementInfoInterface";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";
import { Getter, State } from "vuex-class";
import { UserState } from "@/common/store/user/types";
import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";
import { filter, find, findIndex, forEach, map } from "lodash";

interface PeriodInterface {
  date: Moment;
  confirmed: boolean;
  confirming: boolean;
  pending: boolean;
  id: number;
  periodOfDay: number;
}

interface DayInterface {
  day: Moment;
  dayParts: {
    morning: boolean;
    afternoon: boolean;
  };
}

@Component({
  components: {
    Calendar,
    SavedDialog,
    RequestDialog
  }
})
export default class Requests extends Vue {
  @Prop() substitutionIdRequest!: number;
  @State("user") user!: UserState;
  @Getter("getUserName", { namespace: "user" }) userName!: string;

  replacements: { [key: string]: ReplacementInfoInterface[] } = {};
  activeInfoCardMonth = moment();
  activeInfoCard: {
    classes: string[];
    endDate: string;
    id: number;
    kind: string;
    note: string;
    periods: PeriodInterface[];
    school: {
      address: string;
      name: string;
    };
    startDate: string;
    substituting: string;
  } = {
    classes: [],
    endDate: "",
    id: 0,
    kind: "",
    note: "",
    periods: [],
    school: {
      address: "",
      name: ""
    },
    startDate: "",
    substituting: ""
  };

  editingDay: {
    day: Moment;
    confirming: boolean;
    dayParts: {
      morning: {
        value: null | boolean;
        disabled: boolean;
      };
      afternoon: {
        value: null | boolean;
        disabled: boolean;
      };
    };
  } = {
    day: moment(),
    confirming: true,
    dayParts: {
      morning: {
        value: null,
        disabled: false
      },
      afternoon: {
        value: null,
        disabled: false
      }
    }
  };
  activeDate: Moment = moment();
  currentMonth: Moment = moment().startOf("month");
  dialogVisible = false;
  editDayDialogVisible = false;
  commentDialogVisible = false;
  dialogSavedVisible = false;
  substitutionId = 0;

  async mounted() {
    if (this.substitutionIdRequest) {
      this.openReplacementDialog(this.substitutionIdRequest);
    }
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const responseSubstitutions: void | AxiosResponse = await axios
        .get("substitutions/open", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          },
          params: {
            start: this.formatDate(moment(), "YYYY-MM-DD"),
            end: this.formatDate(moment().add(1, "year"), "YYYY-MM-DD")
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (responseSubstitutions && responseSubstitutions.data) {
        this.replacements = responseSubstitutions.data.items;
      }
    }
  }

  get daysWithEvents() {
    if (this.activeInfoCard.periods.length > 0) {
      const confirmedDays: DayInterface[] = [];
      const pendingDays: DayInterface[] = [];
      const rejectedDays: DayInterface[] = [];

      forEach(this.activeInfoCard.periods, function(period: PeriodInterface) {
        if (period.pending && !period.confirming) {
          const foundIndex = findIndex(pendingDays, function(
            pendingDay: DayInterface
          ) {
            return pendingDay.day.isSame(moment(period.date), "day");
          });

          if (foundIndex >= 0) {
            if (period.periodOfDay === 1) {
              pendingDays[foundIndex].dayParts.morning = true;
            } else if (period.periodOfDay === 2) {
              pendingDays[foundIndex].dayParts.afternoon = true;
            }
          } else {
            pendingDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          }
        } else {
          if (period.confirmed) {
            confirmedDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          } else {
            rejectedDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          }
        }
      });

      return [
        {
          eventType: "single-event",
          color: "green",
          days: confirmedDays
        },
        {
          eventType: "single-event",
          color: "orange",
          days: pendingDays
        },
        {
          eventType: "single-event",
          color: "red",
          days: rejectedDays
        }
      ];
    } else {
      return [];
    }
  }

  selectDate(selectedDay: Moment) {
    const foundMorning = find(this.activeInfoCard.periods, function(
      period: PeriodInterface
    ) {
      return (
        moment(period.date).isSame(moment(selectedDay), "day") &&
        period.periodOfDay === 1
      );
    });

    const foundAfternoon = find(this.activeInfoCard.periods, function(
      period: PeriodInterface
    ) {
      return (
        moment(period.date).isSame(moment(selectedDay), "day") &&
        period.periodOfDay === 2
      );
    });

    if (foundMorning || foundAfternoon) {
      this.editingDay = {
        day: moment(selectedDay),
        confirming: true,
        dayParts: {
          morning: {
            value:
              foundMorning && foundMorning.confirming
                ? foundMorning.confirmed
                : null,
            disabled: foundMorning ? !foundMorning.pending : true
          },
          afternoon: {
            value:
              foundAfternoon && foundAfternoon.confirming
                ? foundAfternoon.confirmed
                : null,
            disabled: foundAfternoon ? !foundAfternoon.pending : true
          }
        }
      };

      this.editDayDialogVisible = true;
    }
  }

  calculateDate(startDate: Moment, endDate: Moment) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);

    if (moment().isBefore(startDateMoment)) {
      return (
        "<strong>" +
        startDateMoment.format("DD MMM") +
        "</strong><br /> \n <div class='small-text'>over " +
        startDateMoment.diff(moment(), "days") +
        " dagen</span>"
      );
    } else if (moment().isAfter(endDateMoment)) {
      return "Verlopen";
    } else if (moment().isBetween(startDateMoment, endDateMoment, "day")) {
      return "<div class='is-today'>Vandaag</div>";
    }
  }

  async confirmDays() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .post(
          "substitutions/" + this.substitutionId + "/respond",
          {
            confirmed: map(
              filter(this.activeInfoCard.periods, function(
                period: PeriodInterface
              ) {
                return period.pending && period.confirming && period.confirmed;
              }),
              "id"
            ),
            rejected: map(
              filter(this.activeInfoCard.periods, function(
                period: PeriodInterface
              ) {
                return period.pending && period.confirming && !period.confirmed;
              }),
              "id"
            )
          },
          {
            headers: {
              "X-VVTOOL-TENANT": this.user.currentTenant.id
            }
          }
        )
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        console.log(response.data);
      }
    }

    this.dialogSavedVisible = true;
  }

  handleClose() {
    this.dialogVisible = false;
  }

  handleCommentClose() {
    this.commentDialogVisible = false;
  }

  handleSaveClose() {
    this.dialogSavedVisible = false;
    this.dialogVisible = false;
  }

  setAvailability(value: { dayPart: string; value: boolean }) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const found = find(this.activeInfoCard.periods, function(
      period: PeriodInterface
    ) {
      return (
        moment(period.date).isSame(self.editingDay.day, "day") &&
        ((value.dayPart === "morning" && period.periodOfDay === 1) ||
          (value.dayPart === "afternoon" && period.periodOfDay === 2))
      );
    });

    if (found && this.activeInfoCard.periods) {
      this.activeInfoCard.periods = map(this.activeInfoCard.periods, function(
        period: PeriodInterface
      ) {
        if (found.id === period.id) {
          period.confirmed = value.value;
          period.confirming = true;
        }
        return period;
      });

      this.editingDay = {
        ...this.editingDay,
        dayParts: {
          ...this.editingDay.dayParts,
          [value.dayPart]: {
            value: value.value,
            disabled: false
          }
        }
      };
    }
  }

  saveDayParts() {
    this.editDayDialogVisible = false;
  }

  async openReplacementDialog(id: number) {
    this.substitutionId = id;
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .get("substitutions/" + id, {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        this.activeInfoCard = response.data;
      }
      this.dialogVisible = true;
    }
  }

  getMonth(date: Moment) {
    return moment(date).startOf("month");
  }

  previousMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth
      .clone()
      .subtract(1, "M");
  }

  nextMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth.clone().add(1, "M");
  }

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }

  previousDay() {
    let previousDay = this.activeDate.clone().subtract(1, "days");
    if (previousDay.day() == 0) {
      previousDay = previousDay.subtract(2, "days");
    } else if (previousDay.day() == 6) {
      previousDay = previousDay.subtract(1, "days");
    }

    this.currentMonth = previousDay.clone().startOf("month");

    this.activeDate = previousDay;
  }

  nextDay() {
    let nextDay = this.activeDate.clone().add(1, "days");
    if (nextDay.day() == 0) {
      nextDay = nextDay.add(1, "days");
    } else if (nextDay.day() == 6) {
      nextDay = nextDay.add(2, "days");
    }

    this.currentMonth = nextDay.clone().startOf("month");

    this.activeDate = nextDay;
  }

  changeDate(date: Moment) {
    this.activeDate = date.startOf("day");
    this.dialogVisible = false;
  }

  capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
}
