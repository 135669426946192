import { render, staticRenderFns } from "./SavedDialog.vue?vue&type=template&id=7b0fb26f&"
import script from "./SavedDialog.vue?vue&type=script&lang=ts&"
export * from "./SavedDialog.vue?vue&type=script&lang=ts&"
import style0 from "./SavedDialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports